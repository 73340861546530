<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="(기간)조치완료 요청일"
            name="actionCompleteRequestDates"
            v-model="searchParam.actionCompleteRequestDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="IBM_TASK_TYPE_CD"
            :rejectItems="['ITT0000145']"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmTaskTypeCd"
            label="업무"
            v-model="searchParam.ibmTaskTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            stepperGrpCd="IBM_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmStepCd"
            label="진행단계"
            v-model="searchParam.ibmStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="IBM_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmClassCd"
            label="개선구분"
            v-model="searchParam.ibmClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :isFlag="true"
            falseLabel="All"
            label="지연된 업무 확인"
            name="overFlag"
            v-model="searchParam.overFlag"
          />
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.ibmStepCd)]">
                    {{ props.row.ibmStepName }}
                  </div>
                  <div :class="['text-grid-etc', getColorStatus2(props.row.ibmTaskTypeCd)]">
                    {{ props.row.ibmTaskTypeName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.ibmTitle }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.improveRequestDeptName }}
                    <span>
                      <q-badge v-if="props.row.completeDateOver > 0" color="negative" label="지연" />
                    </span>
                  </div>
                  <div class="text-grid-etc" :style="props.row.approvalStatusCd === 'ASC9999999' ? 'text-decoration: line-through' : ''">
                    ~ {{ props.row.actionCompleteRequestDate }}
                    <q-badge v-if="props.row.completeDateOver > 0
                      && props.row.ibmStepCd !== 'IS00000001' && props.row.approvalStatusCd !== 'ASC9999999'" color="negative" label="지연" class="blinking" />
                  </div>
                  <div v-if="props.row.approvalStatusCd === 'ASC9999999'">👉 ~ {{props.row.targetDate}}</div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="bg-grey-2 text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'constructionRelactionTaskImpr',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          plantCd: null,
          ibmTaskTypeCd: null,
          actionCompleteRequestDates: [],
          sopConstructionId: '',
        }
      },
    },
    task: {
      type: Object,
      default: () => ({
        insertUrl: '',
        deleteUrl: '',
      }),
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        actionCompleteRequestDates: [],
        ibmTaskTypeCd: null,
        stepCd: null,
        actionFlag: 'D',
        overFlag: 'N',
        ibmClassCd: null,
        sopConstructionId: '',
        constructionFlag: true,
      },
      grid: {
        columns: [
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.list.url
      // code setting
      this.$set(this.searchParam, 'sopConstructionId', this.popupParam.sopConstructionId)
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'IS00000001': // 요청중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'IS00000005': // 조치부서 접수중
          cls = 'txt-box-grid text-positive-box';
          break;
        case 'IS00000010': // 조치부서 조치중
          cls = 'txt-box-grid text-accent-box';
          break;
        case 'IS00000015': // 요청부서 검토중
          cls = 'txt-box-grid text-red-box';
          break;
        case 'IS00000020': // 요청부서 검토중
          cls = 'txt-box-grid text-orange-box';
          break;
      }
      return cls;
    },
    getColorStatus2() {
      return 'txt-box-grid text-gray-box';
    },
    /* eslint-disable no-unused-vars */
    // linkClick(row, col, index) {
    //   if (!row || row.ibmClassCd == 'IC00000001') {// 즉시조치
    //     this.popupOptions.title = '개선 상세'; // 개선 상세
    //     this.popupOptions.isFull = true;
    //     this.popupOptions.param = {
    //       sopImprovementId: row.sopImprovementId,
    //       ibmTaskTypeCd: row.ibmTaskTypeCd,
    //     };
    //     this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
    //   } else {
    //     this.popupOptions.title = '즉시조치';
    //     this.popupOptions.isFull = false;
    //     this.popupOptions.param = {
    //       sopImprovementId: row.sopImprovementId,
    //       ibmTaskTypeCd: row.ibmTaskTypeCd,
    //     };
    //     this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
    //     this.popupOptions.width = '80%';
    //   }
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closePopup;
    // },
    // closePopup() {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   this.getList();
    // },
    // deleteTask() {
    //   let selectData = this.$refs['tasktable'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: '안내', // 안내
    //       message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
    //       type: 'warning', // success / info / warning / error
    //     });
    //   } else {
    //     window.getApp.$emit('CONFIRM', {
    //       title: '확인',
    //       message: '제외하시겠습니까?',
    //       // TODO : 필요시 추가하세요.
    //       type: 'info', // success / info / warning / error
    //       // 확인 callback 함수
    //       confirmCallback: () => {
    //         this.$http.url = this.task.deleteUrl;
    //         this.$http.type = 'DELETE';
    //         this.$http.param = {
    //           data: Object.values(this.$_.map(selectData, (item) => {
    //             return {
    //               sopConstructionId: this.popupParam.sopConstructionId,
    //               sopImprovementId: item.sopImprovementId,
    //             }
    //           }))
    //         };
    //         this.$http.request(() => {
    //           window.getApp.$emit('APP_REQUEST_SUCCESS');
    //           this.$refs['tasktable'].$refs['compo-table'].clearSelection();
    //           this.getList();
    //         },);
    //       },
    //       // 취소 callback 함수
    //       cancelCallback: () => {
    //       },
    //     });
    //   }
    // },
    // addTask() {
    //   this.popupOptions.title = '개선 검색'; // 개선 검색
    //   this.popupOptions.param = {
    //     type: 'multiple'
    //   };
    //   this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprPop.vue'}`);
    //   this.popupOptions.width = '80%';
    //   this.popupOptions.isFull = false;
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeTaskPopup;
    // },
    // closeTaskPopup(data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if (data && data.length > 0) {
    //     let insertList = [];
    //     this.$_.forEach(data, item => {
    //       if (this.$_.findIndex(this.grid.data, { sopImprovementId: item.sopImprovementId }) === -1) {
    //         insertList.push({
    //           sopConstructionId: this.popupParam.sopConstructionId,
    //           sopImprovementId: item.sopImprovementId,
    //         });
    //       }
    //     })

    //     this.$http.url = this.task.insertUrl;
    //     this.$http.type = 'POST';
    //     this.$http.param = insertList
    //     this.$http.request(() => {
    //       window.getApp.$emit('APP_REQUEST_SUCCESS');
    //       this.getList();
    //     },);
    //   }
    // },
  }
};
</script>
